@import '../../styles/vars';

.creditadvicelist {

  &__container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__wrapper {
    min-width: 900px;
    width: $maxTableWidth;
    margin: 0 auto;
  }

  &__title {
    flex: 1 1 100%;
    padding-bottom: 1em;
  }

  &__noCreditAdvicesMessage {
    padding: 1.5em 0;
    text-align: center;
  }

  &__actionColumn {
    width: 5rem;
  }

  &__icon {
    &--edit {

      color: $grey-3;
      transition: all 0.3s;

      &:hover {
        color: $bubblegum-red;
      }
    }
  }

}

  .table__head {
    background-color: $bubblegum-red;

    & th {
      color: #ffffff;
      font-weight: 600;
    }
  }


  .pill {
    padding: 0.4em .2em 0.2em .4em;
    background-color: $grey-3;
    border-radius: 50px;
    text-align: center;
    color: #ffffff;
    box-shadow: 0px 1.25px 2.5px rgba(0, 0, 0, 0.15);
    font-size: 0.85em;
    font-weight: 600;

    &__creditAdviceStatus {
      max-width: 130px;
    }

    &--yellow {
      background-color: $bubblegum-yellow;
    }

    &--red {
      background-color: $bubblegum-red;
    }

    &--green {
      background-color: $bubblegum-green;
    }

    &--blue {
      background-color: $bubblegum-blue;
    }
  }
.creditAdviceTitleAndButtons {
  display: flex;
  padding-left: 2em;
  justify-content: space-between;
  align-items: center;
  transition: all .3s;
  height: 50px;
}
.creditAdviceUsagesButtons {
  display: flex;
  gap: 0 0.5em;
  padding: 0 2em 0 2em;
  min-width: 270px;
  justify-content: flex-end;

  @media (max-width: $purchasefilter-breakpoint) {
    flex-direction: column;
    gap: 0.5rem;
  }

  &--create {
    background-color: $bubblegum-red !important;
    color: #ffffff !important;

    &:hover {
      background-color: #FF0954 !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  .Mui-disabled {
    background: $grey-5 !important;
    color: #333333 !important;
  }
}
